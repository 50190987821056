import { Injectable } from '@angular/core';
import { MyMonitoringService } from '../Services/logging.service';

interface IClient {
   closeWindow: () => void;
   getAppName: () => string;
}

declare var client: IClient;

@Injectable({
   providedIn: 'root'
})
export class BrowserLockService {

   constructor(
      private _loggingService: MyMonitoringService) {
   }

   public closeWindow(): void {
      let logMsg: string = '';
      if (typeof client !== 'undefined' && typeof client.closeWindow !== 'undefined') {
         logMsg = 'closeWindow will be called.';
         console.log(logMsg);
         this._loggingService.logEvent(logMsg);
         try {
            client.closeWindow();
         }
         catch(e) {
            logMsg = `An exception was thrown when calling closeWindow: ${e}`;
            console.error(logMsg);
            this._loggingService.logException(e);
            this._loggingService.logTrace(logMsg);
         }
      }
      else {
         logMsg = 'closeWindow was called outside a secure browser.';
         console.log(logMsg);
         this._loggingService.logEvent(logMsg);
      }
   }

   public getAppName(): string {
      if (typeof client !== 'undefined' && typeof client.getAppName !== 'undefined') {
         try {
            return client.getAppName();
         }
         catch(e) {
            console.error('e');
         }
      }
      return '';
   }

   public isSecureBrowser(): Promise<boolean> {
      //NOTE: This is temporary. Call client.isSecureBrowser and do token negotiation.
      return new Promise((resolve, reject) => {
         const appName: string = this.getAppName();
         if (appName === 'BrowserLock_win32' || appName === 'Pearson VUE Browser Lock_mac') {
            resolve(true);
         }
         resolve(false);
      });
   }

   private logToAppinsights(error: string) {
      this._loggingService.logTrace(error);
   }
}
