import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../Services/app-service';
import { MyMonitoringService } from '../Services/logging.service';

@Component({
   selector: 'app-machine',
   templateUrl: './machine.component.html',
   styles: ['iframe{border:none;}'],
})
export class MachineComponent implements OnInit {
   @Input() pointerEvents = 'auto';
   @ViewChild('machineIFrame') machineIFrame: any;

   private safeRdpUrl: SafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
   private rdpUrl: string = '';

   constructor(private http: HttpClient, @Inject('BASE_URL') public baseUrl: string, private route: ActivatedRoute, private sanitizer: DomSanitizer, private appService: AppService, private _loggingService: MyMonitoringService) {
   }

   getRdpUrl(): SafeUrl {
      return this.safeRdpUrl;
   }

   ngOnInit(): void {
      this.route.queryParams
         .subscribe(params => {
            this.processSymlink(params['symlink']);
         });
   }

   private processSymlink(symlink: string): void {
      if (symlink) {
         this.appService.getExamsFromHomeDomain()
            .then((domain) => {
               let url = `https://${domain}/rdpdirect.html?symlink=${symlink}&fullBrowser=Full%20browser&fullScreen=Full%20screen&playSound=0&server_bpp=32&mapClipboard=on&mapDisk=off&smoothfont=on&bitmap=on&remotefx=on&gateway=${domain}&action=update`;
               this.logToAppinsights('Setting VM Url for symlink: ' + symlink + '. URL: ' + url);
               if (url != this.rdpUrl) {
                  this.safeRdpUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                  this.rdpUrl = url;
               }
            })
            .catch((e) => {
               this.logToAppinsights('Error in Machine.Component.ts : processSymlink(symlink) for symlink: ' + symlink + '. Error message: ' + e);
               console.log(e);
            });
      }
   }

   refresh(): void {
      var iframe: any = document.getElementsByClassName('machine-iframe');
      iframe[0].src = iframe[0].src;
   }

   public logToAppinsights(error: string) {
      this._loggingService.logTrace(error);
   }
}
