import { ActivatedRoute } from "@angular/router";
import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { AppService } from "./app-service";
import { BrowserLockService } from "./browser-lock-service";
import { environment } from "../../environments/environment";
import { MyMonitoringService } from '../Services/logging.service';

@Injectable({
   providedIn: 'root'
})
export class SignalRService {

   private hubConnection!: signalR.HubConnection;

   constructor(private _route: ActivatedRoute, private _appService: AppService, private _browserLock: BrowserLockService, private _loggingService: MyMonitoringService) { }

   public startConnection(): Promise<void> {
      this.hubConnection = new signalR.HubConnectionBuilder()
         .withUrl(environment.ibtVendorServiceUrl + 'LockdownHub')
         .build();

      return this.start();
   }

   private start(): Promise<void> {
      return this.hubConnection.start()
         .then(() => {
            this._route.queryParams
               .subscribe(params => {
                  let symlink: string = params['symlink'];
                  if (symlink !== null && symlink !== undefined)
                     this.subscribe(symlink)
                        .then(() => {
                           this.addCloseLockdownListener();
                        })
                        .catch(() => {
                           this.logToAppinsights('Error: Failed to connect listener for SymlinkId: ' + symlink);
                           console.log('Error: Failed to connect listener.');
                        })
               });
         })
         .catch(err => console.log('SignalR connection error:' + err));
   }

   public addCloseLockdownListener = () => {
      this.hubConnection.on('closeLockdown', data => {
         this.handleCloseLockdownRequest();
      });
   }

   private handleCloseLockdownRequest(): void {
      this._route.queryParams
         .subscribe(params => {
            let symlink: string = params['symlink'];
            console.log('Received CloseLockdown Request for SymlinkId: ' + symlink);
            this.logToAppinsights('Received CloseLockdown Request for SymlinkId: ' + symlink);
            if (symlink !== null && symlink !== undefined) {
               this.hubConnection.invoke("AcknowlegeCloselockdown", symlink)
                  .then(() => {
                     this._browserLock.closeWindow();
                  })
                  .catch();
            }
            else {
               this.logToAppinsights('Received CloseLockdown Request but symlink value was null or undefined');
            }
         });
   }

   public subscribe(symlink: string): Promise<void> {
      return this.hubConnection.invoke("Subscribe", symlink);
   }

   public logToAppinsights(error: string) {
      this._loggingService.logTrace(error);
   }
}
